import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br, PrevNext, Text } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v9' next='/dz/v11' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第十卷-校量布施功德缘品"
    }}>{`第十卷 校量布施功德缘品`}</h3>
    <Text mdxType="Text">
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cónɡ" zi="从" mdxType="Zu" />
  <Zu yin="zuò" zi="座" mdxType="Zu" />
  <Zu yin="ér" zi="而" mdxType="Zu" />
  <Zu yin="qǐ" zi="起" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hú" zi="胡" mdxType="Zu" />
  <Zu yin="ɡuì" zi="跪" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="ɡuān" zi="观" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiào" zi="校" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="qīnɡ" zi="轻" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="yún" zi="云" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zūn" zi="尊" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="dāo" zi="忉" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="宫" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="huì" zi="会" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="jiào" zi="校" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="功" mdxType="Zu" />
  <Zu yin="dé" zi="德" mdxType="Zu" />
  <Zu yin="qīnɡ" zi="轻" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="dì" zi="谛" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="rǔ" zi="汝" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="yí" zi="疑" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="yào" zi="乐" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="nán" zi="南" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zǎi" zi="宰" mdxType="Zu" />
  <Zu yin="fǔ" zi="辅" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chén" zi="臣" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="长" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chà" zi="刹" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="zuì" zi="最" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="pín" zi="贫" mdxType="Zu" />
  <Zu yin="qiónɡ" zi="穷" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="lónɡ" zi="癃" mdxType="Zu" />
  <Zu yin="cán" zi="残" mdxType="Zu" />
  <Zu yin="yīn" zi="喑" mdxType="Zu" />
  <Zu yin="yǎ" zi="哑" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lónɡ" zi="聋" mdxType="Zu" />
  <Zu yin="chī" zi="痴" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="mù" zi="目" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="wán" zi="完" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="bēi" zi="悲" mdxType="Zu" />
  <Zu yin="xià" zi="下" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="hán" zi="含" mdxType="Zu" />
  <Zu yin="xiào" zi="笑" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qīn" zi="亲" mdxType="Zu" />
  <Zu yin="shǒu" zi="手" mdxType="Zu" />
  <Zu yin="biàn" zi="遍" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruǎn" zi="软" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="wèi" zi="慰" mdxType="Zu" />
  <Zu yin="yù" zi="喻" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="hénɡ" zi="恒" mdxType="Zu" />
  <Zu yin="hé" zi="河" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="功" mdxType="Zu" />
  <Zu yin="dé" zi="德" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zuì" zi="最" mdxType="Zu" />
  <Zu yin="pín" zi="贫" mdxType="Zu" />
  <Zu yin="jiàn" zi="贱" mdxType="Zu" />
  <Zu yin="bèi" zi="辈" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="wán" zi="完" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="qī" zi="七" mdxType="Zu" />
  <Zu yin="bǎo" zi="宝" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="zú" zi="足" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="yònɡ" zi="用" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="tǎ" zi="塔" mdxType="Zu" />
  <Zu yin="sì" zi="寺" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="声" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="pì" zi="辟" mdxType="Zu" />
  <Zu yin="zhī" zi="支" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="躬" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="yínɡ" zi="营" mdxType="Zu" />
  <Zu yin="bàn" zi="办" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="dì" zi="帝" mdxType="Zu" />
  <Zu yin="shì" zi="释" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="fàn" zi="梵" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="xiān" zi="先" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="tǎ" zi="塔" mdxType="Zu" />
  <Zu yin="miào" zi="庙" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǐ" zi="毁" mdxType="Zu" />
  <Zu yin="huài" zi="坏" mdxType="Zu" />
  <Zu yin="pò" zi="破" mdxType="Zu" />
  <Zu yin="luò" zi="落" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="bǔ" zi="补" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="yínɡ" zi="营" mdxType="Zu" />
  <Zu yin="bàn" zi="办" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="quàn" zi="劝" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="jié" zi="结" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="lún" zi="轮" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="tā" zi="他" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tónɡ" zi="同" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="tǎ" zi="塔" mdxType="Zu" />
  <Zu yin="miào" zi="庙" mdxType="Zu" />
  <Zu yin="qián" zi="前" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="biān" zi="边" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jiàn" zi="见" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="lǎo" zi="老" mdxType="Zu" />
  <Zu yin="bìnɡ" zi="病" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="chǎn" zi="产" mdxType="Zu" />
  <Zu yin="fù" zi="妇" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="niàn" zi="念" mdxType="Zu" />
  <Zu yin="jiān" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="cí" zi="慈" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="yī" zi="医" mdxType="Zu" />
  <Zu yin="yào" zi="药" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="wò" zi="卧" mdxType="Zu" />
  <Zu yin="jù" zi="具" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="ān" zi="安" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="zuì" zi="最" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="sī" zi="思" mdxType="Zu" />
  <Zu yin="yì" zi="议" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="净" mdxType="Zu" />
  <Zu yin="jū" zi="居" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="liù" zi="六" mdxType="Zu" />
  <Zu yin="yù" zi="欲" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="zhǔ" zi="主" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒnɡ" zi="永" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="duò" zi="堕" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ěr" zi="耳" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="声" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="luó" zi="罗" mdxType="Zu" />
  <Zu yin="mén" zi="门" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="zuò" zi="作" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bì" zi="毕" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="竟" mdxType="Zu" />
  <Zu yin="chénɡ" zi="成" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="shì" zi="释" mdxType="Zu" />
  <Zu yin="fàn" zi="梵" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="lún" zi="轮" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="pǔ" zi="普" mdxType="Zu" />
  <Zu yin="quàn" zi="劝" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="dānɡ" zi="当" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="xué" zi="学" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="shǎo" zi="少" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="máo" zi="毛" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="chén" zi="尘" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="xǔ" zi="许" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="yù" zi="喻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="pì" zi="辟" mdxType="Zu" />
  <Zu yin="zhī" zi="支" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="zhuǎn" zi="转" mdxType="Zu" />
  <Zu yin="lún" zi="轮" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="xínɡ" zi="形" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="像" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shènɡ" zi="胜" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="yù" zi="喻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chénɡ" zi="乘" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jì" zi="偈" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="jù" zi="句" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="yīn" zi="殷" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="重" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="huò" zi="获" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="liànɡ" zi="量" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="biān" zi="边" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="fú" zi="福" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="kě" zi="可" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="yù" zi="喻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="tǎ" zi="塔" mdxType="Zu" />
  <Zu yin="sì" zi="寺" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="chénɡ" zi="乘" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="xīn" zi="新" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhān" zi="瞻" mdxType="Zu" />
  <Zu yin="lǐ" zi="礼" mdxType="Zu" />
  <Zu yin="zàn" zi="赞" mdxType="Zu" />
  <Zu yin="tàn" zi="叹" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="恭" mdxType="Zu" />
  <Zu yin="jìnɡ" zi="敬" mdxType="Zu" />
  <Zu yin="hé" zi="合" mdxType="Zu" />
  <Zu yin="zhǎnɡ" zi="掌" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yù" zi="遇" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="huǐ" zi="毁" mdxType="Zu" />
  <Zu yin="huài" zi="坏" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="bǔ" zi="补" mdxType="Zu" />
  <Zu yin="yínɡ" zi="营" mdxType="Zu" />
  <Zu yin="lǐ" zi="理" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="dú" zi="独" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="quàn" zi="劝" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="tónɡ" zi="同" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="共" mdxType="Zu" />
  <Zu yin="fā" zi="发" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bèi" zi="辈" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="shí" zi="十" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tán" zi="檀" mdxType="Zu" />
  <Zu yin="yuè" zi="越" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chánɡ" zi="常" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="lún" zi="轮" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hái" zi="还" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="jiào" zi="教" mdxType="Zu" />
  <Zu yin="huà" zi="化" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="ɡuó" zi="国" mdxType="Zu" />
  <Zu yin="wánɡ" zi="王" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="cì" zi="次" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nán" zi="男" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="nǚ" zi="女" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yú" zi="于" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="ɡēn" zi="根" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="ɡònɡ" zi="供" mdxType="Zu" />
  <Zu yin="yǎnɡ" zi="养" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="xiū" zi="修" mdxType="Zu" />
  <Zu yin="bǔ" zi="补" mdxType="Zu" />
  <Zu yin="tǎ" zi="塔" mdxType="Zu" />
  <Zu yin="sì" zi="寺" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zhuānɡ" zi="装" mdxType="Zu" />
  <Zu yin="lǐ" zi="理" mdxType="Zu" />
  <Zu yin="jīnɡ" zi="经" mdxType="Zu" />
  <Zu yin="diǎn" zi="典" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="máo" zi="毛" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="chén" zi="尘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yì" zi="一" mdxType="Zu" />
  <Zu yin="shā" zi="沙" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="dī" zi="渧" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shàn" zi="善" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="ɡōnɡ" zi="功" mdxType="Zu" />
  <Zu yin="dé" zi="德" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="shànɡ" zi="上" mdxType="Zu" />
  <Zu yin="miào" zi="妙" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="huí" zi="回" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="jiā" zi="家" mdxType="Zu" />
  <Zu yin="juàn" zi="眷" mdxType="Zu" />
  <Zu yin="shǔ" zi="属" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="zì" zi="自" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="lì" zi="利" mdxType="Zu" />
  <Zu yin="yì" zi="益" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="即" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="lè" zi="乐" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shě" zi="舍" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="dé" zi="得" mdxType="Zu" />
  <Zu yin="wàn" zi="万" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bù" zi="布" mdxType="Zu" />
  <Zu yin="shī" zi="施" mdxType="Zu" />
  <Zu yin="yīn" zi="因" mdxType="Zu" />
  <Zu yin="yuán" zi="缘" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v9' next='/dz/v11' mdxType="PrevNext" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      